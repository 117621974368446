import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <div
      className="w-full bg-slate-900 p-11 text-white flex justify-between flex-wrap"
      id="footer"
    >
      <div className="flex">
        <div className="mx-4">
          <Link to="/contacts">{t("footer.contacts")}</Link>
        </div>
        <div className="mx-4">
          <Link to="/terms">{t("footer.terms")}</Link>
        </div>
        <div>
          <Link to="/faq">{t("footer.faq")}</Link>
        </div>
      </div>
      <div className="text-right mx-5 flex-1 mt-5 flex flex-col items-center md:block md:mt-0">
        <div>® Trallid.ee</div>
        <div>OÜ Firm and steady</div>
        <div>Reg. 17047649</div>
        <div>Tallinn</div>
      </div>
    </div>
  );
};
