{
  "landing": {
    "hero-title": "Leia seiklus ja tähistamine ja õppimise kogemusi",
    "title2": "Broneeri hämmastav kogemus",
    "subtitle2": "Üks, mida mäletate pikka aega",
    "bouldering": "Bouldering",
    "paintball": "Paintball",
    "escape-rooms": "Põgenemistoad",
    "kids-play-rooms": "Laste mängutoad",
    "museums": "Muuseumides",
    "outside": "Väljaspool osapooli",
    "vr": "VR",
    "calligraphy": "Kalligraafia"
  },
  "filter": {
    "all": "Kõik",
    "city": "Linn",
    "selectCity": "Valige linn",
    "age": "Vanus",
    "selectAge": "Valige vanus",
    "activity": "Tegevus",
    "selectActivity": "Valige tegevuse tüüp",
    "Tallinn": "Tallinn",
    "kids": "lapsed",
    "adults": "täiskasvanud",
    "teenager": "teismeline",
    "birthdays": "Laste Sünnipäevadeks",
    "sport": "Sport ja vaba aeg",
    "art": "Kunst Ja Käsitöö",
    "education": "Haridus",
    "teambuilding": "Team building",
    "picnic": "Picnic",
    "events": "Events"
  },
  "ads": {
    "showing": "Kuvatakse",
    "out-of": "/",
    "ads": "reklaami"
  },
  "selectors": {
    "find": "Leia"
  },
  "footer": {
    "contacts": "Ühendus",
    "terms": "Kasutustingimused",
    "faq": "KKK"
  },
  "contacts": {
    "title": "Ühendus"
  },
  "faq": {
    "title": "KKK",
    "question-1": "- Mis siin toimub?",
    "answer-1": "- Veebisaidil kogutakse kataloogi ürituspaikadest nagu ettevõtteüritused või sünnipäevad.",
    "question-2": "- Kas ma pean maksma või registreeruma?",
    "answer-2": "- Ei, me oleme kataloog, aitame leida ürituskohti ja nendega ühendust võtta.",
    "question-3": "- Kui ma saadan ettevõtte lehele sõnumi kuupäeva ja kommentaariga, mis juhtub?",
    "answer-3": "- Suuname selle ettevõtte e-posti, et nad saaksid teiega ühendust võtta."
  },
  "terms": {
    "title": "Kasutustingimused",
    "paragraph-1": "Seda veebisaiti omab OÜ Firm and steady (Reg: 17047649), kes elab Eestis. Sait pakub kõigile internetikasutajatele tasuta ja piiramatut juurdepääsu. Veebisaidi peamine eesmärk on pakkuda ettevõtete kataloogiteenust, kus saab korraldada pidustusi ja ettevõtteüritusi, ning võimaldada nende ettevõtetega kontakteerumist.",
    "paragraph-2": "Oluline on märkida, et see veebisait ei kogu ega salvesta kasutajaandmeid. Kogu veebisaidil pakutav teave on avalikult kättesaadav ja ettevõtete poolt võib seda muuta eelneva teatamiseta. Sait ei vastuta pidustuste toimumiskohtade ja aegade täpsuse eest ega kinnita nende autentsust. Kasutajatel soovitatakse teavet iseseisvalt kontrollida ja selgitada detaile otse ettevõtetega.",
    "paragraph-3": "Kasutades seda veebisaiti, nõustute automaatselt eespool nimetatud tingimustega. Kui teil on küsimusi või ettepanekuid veebisaidi kasutamise kohta, võtke meiega ühendust pakutud kontaktandmete kaudu."
  }
}
