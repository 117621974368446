import React from "react";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { useTranslation } from "react-i18next";

export const Contacts = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="h-[calc(100%-276px)] flex justify-center items-center flex-col">
        <div className="font-bold">{t("contacts.title")}</div>
        <div className="mb-4">mikheyrojo@gmail.com</div>
        <div className="flex gap-4 mb-4">
          <a
            href="https://t.me/let_s_family"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              width="40"
              height="40"
              src="https://upload.wikimedia.org/wikipedia/commons/8/82/Telegram_logo.svg"
              alt="Telegram"
            />
          </a>
          <a
            href="https://www.instagram.com/let_s_family?igsh=Mmk1d2NlNjVpM3g3"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              width="40"
              height="40"
              src="https://upload.wikimedia.org/wikipedia/commons/e/e7/Instagram_logo_2016.svg"
              alt="Instagram"
            />
          </a>
        </div>
        <img
          width="100"
          height="100"
          src="https://res.cloudinary.com/dhlzvktzj/image/upload/v1710593110/sunni/star-pirate_mpr4hk.svg"
        />
      </div>
      <Footer />
    </>
  );
};
